import { SET_CARTITEMCOUNT } from "../actions";

const cartitemcount = (state = [], action) => {
  switch (action.type) {
    case SET_CARTITEMCOUNT:
      return [...action.value];
    default:
      return state;
  }
};

export default cartitemcount;
