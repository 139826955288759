import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_AVAILABLE_DATE, SET_AVAILABLE_DATE, GET_DEFAULTAVL_DATE, SET_DEFAULTAVL_DATE } from '../actions';
import { appId, apiUrlV2, cateringId } from "../components/Helpers/Config";
import Axios from 'axios';

export const watchGetAvailabileDateList = function* () {
  yield takeEvery(GET_AVAILABLE_DATE, workerGetAvailabileDateList);
}

export const watchGetDefaultAvlDateList = function* () {
  yield takeEvery(GET_DEFAULTAVL_DATE, workerGetDefaultAvlDateList);
}

function* workerGetAvailabileDateList({ avlDateFlg }) {
  try {
    const uri = apiUrlV2+'catering/availableDates?app_id='+appId+"&availability_id="+cateringId+"&dateFlagkey=" + avlDateFlg['dateFlagkey'] + "&outletId=" + avlDateFlg['outletId'] + "&nextDate=" + avlDateFlg['nextDate'] + "&prevDate=" + avlDateFlg['prevDate'] + "&";
	
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_AVAILABLE_DATE, value: resultArr });
  } 
  catch {
    console.log('Get Availabile Date Failed');
  }
}

function* workerGetDefaultAvlDateList({ avlDateFlg }) {
  try {
    /*const uri = apiUrlV2+'catering/availableDatesDefault?app_id='+appId+"&strTime="+avlDateFlg['strTime'] + "&endTime=" + avlDateFlg['endTime'] + "&dateFlagkey=" + avlDateFlg['dateFlagkey'] + "&nextDate=" + avlDateFlg['nextDate'] + "&prevDate=" + avlDateFlg['prevDate'] + "&intervalTime=" + avlDateFlg['intervalTime'] + "&minimumDate=" + avlDateFlg['minimumDate'] + "&maximumDate=" + avlDateFlg['maximumDate'] + "&";*/
	
	const uri = apiUrlV2+'catering/availableDatesMonthBased?app_id='+appId+"&strTime="+avlDateFlg['strTime'] + "&endTime=" + avlDateFlg['endTime'] + "&dateFlagkey=" + avlDateFlg['dateFlagkey'] + "&nextDate=" + avlDateFlg['nextDate'] + "&prevDate=" + avlDateFlg['prevDate'] + "&selectedDate=" + avlDateFlg['selectedDate'] + "&intervalTime=" + avlDateFlg['intervalTime'] + "&minimumDate=" + avlDateFlg['minimumDate'] + "&maximumDate=" + avlDateFlg['maximumDate'] + "&";
	
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_DEFAULTAVL_DATE, value: resultArr });
  } 
  catch {
    console.log('Get Default Availabile Date Failed');
  }
} 
